<template>
  <div class="dealCategory">
    <header class="header-area">
      <div class="container">
        <figure class="logo">
          <img :src="`${imageDomain}/assets/img/app/logo2.png`" alt="logo" />
        </figure>
        <button v-if="!openSupportPage" class="btn-close pr-2" @click="showFilterPanel">
          <i class="fa-solid fa-xmark"></i>
        </button>
        <button v-else class="btn-close pr-2" @click="showMenuDealCategory">
          <i class="fa-solid fa-chevron-right"></i>
        </button>
      </div>
    </header>
    <main class="body_warrper">
      <div class="search-area">
        <div class="sape1"></div>
        <div class="sape2"></div>
        <div class="sape3"></div>
        <div class="sape4"></div>
        <div v-if="!openSupportPage" class="body">
          <div class="col-lg-6 box-main" v-for="(item, index) in items" :key="index">
            <div class="box">
              <div class="w-100 pointer" @click="pickMenu(item)">
                <img :src="imgLink(item)" :alt="title(item)" />
                <p>{{title(item)}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="support">
          <div class="row">
              <div class="col-lg-12 box-main">
                  <div class="box1 m-auto">
                      <div class="w-100">
                          <div class="sheroot">שרות לקוחות</div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-12 box-main2">
                  <div class="box1 m-auto">
                      <div class="w-100">
                          <p class="nismah">נתקלתם בבעיה? נשמח לעזור</p>
                          <p class="nismah">צור קשר :</p>
                      </div>
                  </div>
              </div>

          </div>
          <div class="row">
              <div class="col-lg-6 col-6">
                  <div class="box2">
                      <div class="w-100">

                          <a href="tel:035151606" class="">
                              <img :src="`${imageDomain}/assets/img/app/call.png`" alt="call">
                          </a>
                          <p class="top-box">חייגו לשרות לקוחות</p>
                      </div>
                  </div>
              </div>
              <div class="col-lg-6 col-6">
                  <div class="box2">
                      <div class="w-100">

                          <a href="mailto:sherut@flying.co.il" class="">
                              <img :src="`${imageDomain}/assets/img/app/call.png`" alt="call">
                          </a>
                          <p class="top-box">שלחו פניה בדואר אלקטרוני</p>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row btn-sec">
              <div class="tnaim  ">
                  <a :href="`${domain}/%D7%91%D7%A7%D7%A9%D7%94_%D7%9C%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%94%D7%96%D7%9E%D7%A0%D7%94?channel=App`" class="  btn-tnaim ">
                      טופס ביטול הזמנה
                  </a>
              </div>
              <div class="tnaim  ">
                  <a :href="`${domain}/מדיניות_פרטיות?channel=App`" class="  btn-tnaim ">
                      מדיניות פרטיות
                  </a>
              </div>
              <div class="tnaim  ">
                  <a :href="`${domain}/תנאים_כלליים_והגבלת_אחריות?channel=App`" class="  btn-tnaim ">
                      תנאים כלליים
                  </a>
              </div>
          </div>
        </div>
        <div class="social">
          <button class="btn-social" @click="gotoSupport" :disabled="openSupportPage">
            <div class="icon">
              <img src="/assets/img/app/support.png" alt="support" />
            </div>
            <p>תמיכה</p>
          </button>
          <button class="btn-social" @click="gotoSocial('call')">
            <img src="/assets/img/app/call.png" alt="call" />
            <p>שירות</p>
          </button>
          <button class="btn-social" @click="gotoSocial('facebook')">
            <img src="/assets/img/app/facebook.png" alt="facebook" />
            <p>התחברות</p>
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  name: 'MenuDealCategory',
  mixins: [imageUrlMixin],
  data() {
    return {
      openSupportPage: false,
    };
  },
  computed: {
    ...mapGetters({
      menuItems: 'GET_MENU_ITEMS_APP_DEAL',
    }),
    items() {
      return this.menuItems;
    },
    domain() {
      return window.location.origin;
    },
  },
  methods: {
    showFilterPanel() {
      this.$emit('emitMessage', {
        kind: 'toggleMenu',
        data: null,
      });
    },
    showMenuDealCategory() {
      this.openSupportPage = false;
    },
    imgLink(pItem) {
      return pItem.icon2;
    },
    title(pItem) {
      return pItem.title;
    },
    pickMenu(pItem) {
      this.$emit('emitMessage', {
        kind: 'pickCategory',
        data: {
          code: pItem?.code ?? '',
        },
      });
      this.showFilterPanel();
    },
    gotoSocial(pKind) {
      switch (pKind) {
        case 'facebook':
          window.open('https://www.facebook.com/615543848613430/', '_blank');
          break;
        case 'call':
          window.open('tel://035139924', '_blank');
          break;
        default:
          // console.log(`please add the action of ${pKind}`);
      }
    },
    gotoSupport() {
      this.openSupportPage = true;
    },
  },
};
</script>

<style scoped>

</style>
